
import { defineComponent, computed, ref } from 'vue';
import {
  IonContent,
  // IonHeader,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonToolbar,
  IonSegment,
  IonIcon,
  IonItemOption,
  IonSegmentButton,
  IonSearchbar,
  IonRippleEffect,
  IonItemSliding,
  IonItemOptions,
  IonSkeletonText,
  IonThumbnail,
  IonImg,
} from '@ionic/vue';
import { addCircleSharp, trashOutline } from 'ionicons/icons';
import HeaderMain from '@/components/shared/HeaderMain.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Exhibitor } from '@/types/interface';
import bizvento from '@/services/bizvento';
import { openToast } from '@/helpers/toast';
import EmptyState from '@/components/shared/EmptyState.vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    // IonHeader,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonSearchbar,
    IonRippleEffect,
    HeaderMain,
    IonItemSliding,
    IonItemOptions,
    IonIcon,
    IonItemOption,
    EmptyState,
    IonSkeletonText,
    IonThumbnail,
    IonImg,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref<boolean>(false);

    const search = ref<string>('');
    const exhibitorsSegmentVal = ref<string>('all');

    const fallbackImage = '../assets/fallback/exhibitor.png';

    const exhibitors = computed(() => {
      return (
        (store.state.exhibitors as Exhibitor[]).sort(
          (a: Exhibitor, b: Exhibitor) => a.name.localeCompare(b.name)
        ) || []
      );
    });

    const searchExhibitor = computed(() => {
      return exhibitors.value.filter((exhibitor) => {
        return exhibitor.name.match(new RegExp(search.value, 'i'));
      });
    });

    const favouriteExhibitor = computed((): Exhibitor[] => {
      const favouriteIds = store.state.favouriteExhibitors.map(
        (item: Exhibitor) => item.id
      );
      return searchExhibitor.value.filter(
        (item: Exhibitor) => favouriteIds.indexOf(item.id) > -1
      );
    });

    const addToFavourite = async (exhibitor: Exhibitor) => {
      const inFavourite = favouriteExhibitor.value.find(
        (fav) => fav.id === exhibitor.id
      );
      try {
        if (!inFavourite) {
          isLoading.value = true;
          store.commit('SET_FAVOURITE_EXHIBITOR', exhibitor);
          openToast(
            'Exhibitor has been added to favourites list',
            2000,
            'bottom',
            'success'
          );
          isLoading.value = false;
          await bizvento.user.createFavourite(
            process.env.VUE_APP_EVENT_ID,
            store.state.user.uid,
            String(exhibitor.id),
            'exhibitor'
          );
        } else {
          openToast(
            'Exhibitor already in favourite tab',
            2000,
            'bottom',
            'danger'
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const removeFromFavourite = async (exhibitor: Exhibitor): Promise<void> => {
      try {
        store.commit('REMOVE_FAVOURITE_EXHIBITOR', exhibitor);
        openToast(
          'Exhibitor has been removed from favourites list',
          2000,
          'bottom',
          'danger'
        );
        await bizvento.user.deleteFavourite(
          process.env.VUE_APP_EVENT_ID,
          store.state.user.uid,
          String(exhibitor.id)
        );
      } catch (error) {
        console.log(error);
      }
    };

    return {
      addToFavourite,
      removeFromFavourite,
      isLoading,
      exhibitorsSegmentVal,
      favouriteExhibitor,
      searchExhibitor,
      search,
      exhibitors,
      addCircleSharp,
      trashOutline,
      router,
      fallbackImage,
    };
  },
});
